<template>
	<v-row>
		<v-col md="6">
			<v-layout>
				<v-flex md8>
					<v-menu offset-y v-model="menuSearch" content-class="elevation-2" tile>
						<template v-slot:activator="{ on, attrs }">
							<TextInput
								hide-details
								ref="search-inventory"
								:disabled="searchLoading"
								:loading="searchLoading"
								placeholder="Search..."
								v-model="search"
								:v-bind-attrs="attrs"
								:v-on="on"
								v-on:keyup="searchInventory()"
							></TextInput>
						</template>

						<v-list v-if="inventoryList.length">
							<v-list-item
								v-for="(row, index) in inventoryList"
								:key="index"
								v-on:click="selectInventory(row)"
							>
								<v-list-item-avatar>
									<ItemImage :src="row.image"></ItemImage>
								</v-list-item-avatar>
								<v-list-item-content>
									<v-list-item-title v-html="row.name"></v-list-item-title>
									<v-list-item-subtitle v-html="row.description"></v-list-item-subtitle>
								</v-list-item-content>
							</v-list-item>
						</v-list>
						<v-list v-else>
							<v-list-item>
								<v-list-item-content>
									<v-list-item-title>
										<p class="m-0 row-not-found text-center hide-border-top py-0">
											<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
											<span>Uhh... There are no inventory at the moment.</span>
										</p>
									</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</v-list>
					</v-menu>
				</v-flex>
			</v-layout>
		</v-col>
		<v-col md="12">
			<table width="100%" class="detail-table table-head-sticky border-light-grey">
				<thead>
					<tr>
						<th width="50%" class="p-2 light-dark-blue-bg">Name</th>
						<th width="30%" class="p-2 light-dark-blue-bg" v-if="false">Location</th>
						<th width="20%" class="p-2 light-dark-blue-bg" colspan="2" align="left">Quantity</th>
					</tr>
				</thead>
				<tbody v-if="inventories.length">
					<tr v-for="(row, index) in inventories" :key="index">
						<td width="50%" class="px-2 pb-3 my-auto font-level-1 text-black border-top-light-grey">
							<p class="m-0 my-auto mt-3">{{ row.name }}</p>
						</td>
						<td width="30%" class="px-2 pb-3 font-level-1 text-black border-top-light-grey" v-if="false">
							<AutoCompleteInput
								hide-details
								:items="row.location_list"
								:disabled="formLoading"
								:loading="formLoading"
								placeholder="Location"
								v-model="row.location"
								v-on:change="emitValue()"
							></AutoCompleteInput>
						</td>
						<td width="15%" class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
							<QuantityInput
								hide-details
								:disabled="formLoading"
								:loading="formLoading"
								placeholder="Quantity"
								v-model="row.quantity"
								v-on:keyup="emitValue()"
								v-on:change="emitValue()"
							></QuantityInput>
						</td>
						<td width="5%" class="px-2 pb-3 font-level-1 text-black border-top-light-grey">
							<v-btn v-on:click="removeRow(index)" color="red lighten-1" class="mt-3" icon depressed small>
								<v-icon small>mdi-delete</v-icon>
							</v-btn>
						</td>
					</tr>
				</tbody>
				<tfoot v-else>
					<tr>
						<td colspan="4">
							<p class="m-0 row-not-found text-center">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There are no inventory at the moment.
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</v-col>
	</v-row>
</template>
<script>
import { toSafeInteger, findIndex, cloneDeep } from "lodash";
import { SearchInventory } from "@/core/lib/inventory.lib";
import ItemImage from "@/view/components/Image";
import TextInput from "@/view/components/TextInput";
import QuantityInput from "@/view/components/QuantityInput";
import AutoCompleteInput from "@/view/components/AutoCompleteInput";

export default {
	name: "link-asset-inventory-item",
	title: "Link Asset Perishables Item",
	model: {
		prop: "value",
		event: "input",
	},
	props: {
		value: {
			type: Array,
			default() {
				return [];
			},
		},
	},
	watch: {
		value: {
			deep: true,
			immediate: true,
			handler() {
				this.init();
			},
		},
	},
	data() {
		return {
			inventoryList: [],
			inventories: [],
			searchLoading: false,
			formLoading: false,
			menuSearch: false,
			search: null,
			timeout: null,
			timeoutLimit: 200,
		};
	},
	methods: {
		init() {
			this.inventories = this.value;
		},
		emitValue() {
			this.$nextTick(() => {
				const inventories = cloneDeep(this.inventories);
				this.$emit("input", inventories);
			});
		},
		removeRow(index) {
			this.inventories.splice(index, 1);
			this.emitValue();
		},
		searchInventory() {
			const _this = this;
			if (!_this.search) {
				return false;
			}
			_this.menuSearch = false;
			clearTimeout(_this.timeout);
			_this.timeout = setTimeout(function () {
				if (!_this.search) {
					return false;
				}
				_this.searchLoading = true;
				SearchInventory(_this.search)
					.then((data) => {
						_this.inventoryList = data;
						_this.menuSearch = true;
					})
					.catch((error) => {
						_this.logError(error);
					})
					.finally(() => {
						_this.searchLoading = false;
						_this.$nextTick(() => {
							_this.$refs["search-inventory"].$refs["textInput"].$refs["input"].focus();
						});
					});
			}, _this.timeoutLimit);
		},
		selectInventory(inventory) {
			const index = findIndex(this.inventories, { id: inventory.id });

			if (index >= 0) {
				let quantity = toSafeInteger(this.inventories[index].quantity);
				this.inventories[index].quantity = ++quantity;
				return false;
			}

			this.inventories.push({
				id: inventory.id,
				name: inventory.name,
				location_list: [],
				location: inventory.location_id,
				quantity: 1,
			});

			/*if (inventory && inventory.uuid) {
				this.searchLoading = true;
				GetLocationByInventory(inventory.uuid)
					.then((data) => {
						if (data && data.length) {
							let location_list = map(data, (row) => {
								return { text: row.location_name, value: row.location };
							});

							this.inventories.push({
								id: inventory.id,
								name: inventory.name,
								location_list,
								location: inventory.location_id,
								quantity: 1,
							});

							this.emitValue();
						}
					})
					.catch((error) => {
						this.$emit("error", error);
						this.logError(error);
					})
					.finally(() => {
						this.searchLoading = false;
						this.search = null;
					});
			}*/
		},
	},
	mounted() {
		this.init();
	},
	components: {
		TextInput,
		QuantityInput,
		ItemImage,
		AutoCompleteInput,
	},
};
</script>
