<template>
	<div>
		<Dialog :dialog="dialog" :dialogWidth="dialogWidth">
			<template v-slot:title>
				<h3>Manage Designation</h3>
			</template>
			<template v-slot:body>
				<div>
					<v-form ref="designation-form" v-model="form_valid" lazy-validation>
						<table width="100%" style="table-layout: fixed">
							<tr v-for="(row, index) in db_list" :key="index">
								<td>
									<TextInput
										hide-details
										:disabled="page_loading"
										placeholder="Enter Designation"
										:loading="page_loading"
										:id="`designation-${index}`"
										v-model="row.value"
										:class="{ required: !row.value, 'mb-3 has-delete-outer': true }"
										:rules="[vrules.required(row.value, 'Designation')]"
										append-outer-icon="mdi-delete"
										v-on:click:append-outer="remove_row(index)"
									></TextInput>
								</td>
							</tr>
							<tr>
								<td>
									<v-btn
										tile
										depressed
										:disabled="page_loading"
										:loading="page_loading"
										class="custom-grey-border custom-bold-button white--text"
										color="blue darken-4 white--text"
										v-on:click="push_row()"
									>
										New Designation
									</v-btn>
								</td>
							</tr>
						</table>
					</v-form>
				</div>
			</template>
			<template v-slot:action>
				<v-btn
					tile
					depressed
					:disabled="page_loading"
					:loading="page_loading"
					class="mx-2 custom-grey-border custom-bold-button white--text"
					color="blue darken-4 white--text"
					v-on:click="submit()"
				>
					Save
				</v-btn>
				<v-btn
					tile
					depressed
					:disabled="page_loading"
					class="mx-2 custom-grey-border custom-bold-button"
					v-on:click="$emit('close', true)"
				>
					Close
				</v-btn>
			</template>
		</Dialog>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger, cloneDeep } from "lodash";
import Dialog from "@/view/components/Dialog";
import TextInput from "@/view/components/TextInput";
import ApiService from "@/core/services/api.service";
import { SET_ERROR } from "@/core/services/store/common.module";

export default {
	data() {
		return {
			db_list: [],
			page_loading: false,
			form_valid: true,
		};
	},
	props: {
		list: {
			type: Array,
			default() {
				return [];
			},
		},
		dialog: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Dialog,
		TextInput,
	},
	methods: {
		init() {
			this.db_list = cloneDeep(this.list);
			if (this.db_list.length < 1) {
				this.push_row();
			}
		},
		remove_row(index) {
			this.db_list.splice(index, 1);
			if (this.db_list.length < 1) {
				this.push_row();
			}
		},
		push_row() {
			this.db_list.push({
				value: null,
				uuid: null,
			});

			this.$nextTick(() => {
				const focus_element = `designation-${this.db_list.length - 1}`;
				document.getElementById(focus_element).focus();
			});
		},
		submit() {
			const formErrors = this.validateForm(this.$refs["designation-form"]);

			if (formErrors.length) {
				this.$store.commit(SET_ERROR, this.errors.concat(formErrors));
				return false;
			}

			if (!this.$refs["designation-form"].validate()) {
				return false;
			}

			this.page_loading = true;

			ApiService.setHeader();
			ApiService.patch(`setting/designation`, { list: this.db_list })
				.then(() => {
					this.$emit("close", true);
					this.$emit("success", true);
				})
				.catch((error) => {
					this.logError(error);
				})
				.finally(() => {
					this.page_loading = false;
				});
		},
	},
	mounted() {
		this.init();
	},
	computed: {
		...mapGetters(["errors", "localDB"]),
		dialogWidth() {
			return toSafeInteger((document.body.clientWidth / 100) * 50);
		},
	},
};
</script>
